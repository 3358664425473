import axios from "axios";
import { contactUrl, getHeader } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    showAttachmentFormModal: false,
    showAttachmentShowModal: false
  },
  mutations: {
    SET_ATTACHMENT_FORM_MODAL (state, obj) {
      state.showAttachmentFormModal = obj
    },
    SET_ATTACHMENT_SHOW_MODAL (state, obj) {
      state.showAttachmentShowModal = obj
    }
  },
  actions: {
    enableAttachmentFormModal ({ commit }) {
      commit('SET_ATTACHMENT_FORM_MODAL', true)
    },
    disableAttachmentFormModal ({ commit }) {
      commit('SET_ATTACHMENT_FORM_MODAL', false)
    },
    enableAttachmentShowModal ({ commit }) {
      commit('SET_ATTACHMENT_SHOW_MODAL', true)
    },
    disableAttachmentShowModal ({ commit }) {
      commit('SET_ATTACHMENT_SHOW_MODAL', false)
    }
  }
};
