import axios from "axios";
import { getHeader, orderUrl } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    orderTypeList: [],
    orderList: [],
    orderListOutstanding: [],
    order: {
      id: null,
      order_no: null,
      details: []
    },
    showOrderListModal: false,
    showOrderFormModal: false,
    showOrderListOutstandingModal: false,
    showOrderDetailFormModal: false,
    showOrderPaymentFormModal: false,
    showOrderCreateInventoryFormModal: false,
    showOrderModal: false,
    showOrderPrintPayment: false,
    showOrderNextFormModal: false,
    showQuotationNextFormModal: false
  },
  mutations: {
    SET_ORDER_MODAL (state, obj) {
      state.showOrderModal = obj
    },
    SET_ORDER_TYPE_LIST (state, obj) {
      state.orderTypeList = obj
    },
    SET_ORDER_LIST (state, obj) {
      state.orderList = obj
    },
    SET_ORDER_LIST_OUTSTANDING (state, obj) {
      state.orderListOutstanding = obj
    },
    SET_ORDER (state, obj) {
      state.order = obj
    },
    SHOW_ORDER_LIST_MODAL (state, obj) {
      state.showOrderListModal = obj
    },
    SHOW_ORDER_FORM_MODAL (state, obj) {
      state.showOrderFormModal = obj
    },
    SHOW_ORDER_LIST_OUTSTANDING_MODAL (state, obj) {
      state.showOrderListOutstandingModal = obj
    },
    SHOW_ORDER_DETAIL_FORM_MODAL (state, obj) {
      state.showOrderDetailFormModal = obj
    },
    SHOW_ORDER_PAYMENT_FORM_MODAL (state, obj) {
      state.showOrderPaymentFormModal = obj
    },
    SHOW_ORDER_CREATE_INVENTORY_FORM_MODAL (state, obj) {
      state.showOrderCreateInventoryFormModal = obj
    },
    SHOW_ORDER_PRINT_PAYMENT_MODAL (state, obj) {
      state.showOrderPrintPayment = obj
    },
    SHOW_ORDER_NEXT_FORM_MODAL (state, obj) {
      state.showOrderNextFormModal = obj
    },
    SHOW_QUOTATION_NEXT_FORM_MODAL (state, obj) {
      state.showQuotationNextFormModal = obj
    }
  },
  actions: {
    enableShowQuotationNextFormModal ({ commit }) {
      commit('SHOW_QUOTATION_NEXT_FORM_MODAL', true)
    },
    disableShowQuotationNextFormModal ({ commit }) {
      commit('SHOW_QUOTATION_NEXT_FORM_MODAL', false)
    },
    enableShowOrderNextFormModal ({ commit }) {
      commit('SHOW_ORDER_NEXT_FORM_MODAL', true)
    },
    disableShowOrderNextFormModal ({ commit }) {
      commit('SHOW_ORDER_NEXT_FORM_MODAL', false)
    },
    enableOrderPrintPaymentModal ({ commit }) {
      commit('SHOW_ORDER_PRINT_PAYMENT_MODAL', true)
    },
    disableOrderPrintPaymentModal ({ commit }) {
      commit('SHOW_ORDER_PRINT_PAYMENT_MODAL', false)
    },
    enableOrderModal ({ commit }) {
      commit('SET_ORDER_MODAL', true)
    },
    disableOrderModal ({ commit }) {
      commit('SET_ORDER_MODAL', false)
    },
    enableOrderCreateInventoryFormModal ({ commit }) {
      commit('SHOW_ORDER_CREATE_INVENTORY_FORM_MODAL', true)
    },
    disableOrderCreateInventoryFormModal ({ commit }) {
      commit('SHOW_ORDER_CREATE_INVENTORY_FORM_MODAL', false)
    },
    enableOrderPaymentFormModal ({ commit }) {
      commit('SHOW_ORDER_PAYMENT_FORM_MODAL', true)
    },
    disableOrderPaymentFormModal ({ commit }) {
      commit('SHOW_ORDER_PAYMENT_FORM_MODAL', false)
    },
    enableOrderDetailFormModal ({ commit }) {
      commit('SHOW_ORDER_DETAIL_FORM_MODAL', true)
    },
    diableOrderDetailFormModal ({ commit }) {
      commit('SHOW_ORDER_DETAIL_FORM_MODAL', false)
    },
    enableOrderListOutstandingModal ({ commit }) {
      commit("SHOW_ORDER_LIST_OUTSTANDING_MODAL", true)
    },
    disableOrderListOutstandingModal ({ commit }) {
      commit("SHOW_ORDER_LIST_OUTSTANDING_MODAL", false)
    },
    enableOrderListModal ({ commit }) {
      commit("SHOW_ORDER_LIST_MODAL", true)
    },
    disableOrderListModal ({ commit }) {
      commit("SHOW_ORDER_LIST_MODAL", false)
    },
    enableOrderFormModal ({ commit }) {
      commit("SHOW_ORDER_FORM_MODAL", true)
    },
    disableOrderFormModal ({ commit }) {
      commit("SHOW_ORDER_FORM_MODAL", false)
    },
    async getOrderTypeList({ commit }) {
      try {
        const data = await axios.get(orderUrl + '/type', { headers: getHeader() })
        commit('SET_ORDER_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getOrderListOutstanding({ commit }, payload) {
      try {
        const data = await axios.post(orderUrl + '/list/outstanding', payload, { headers: getHeader() })
        commit('SET_ORDER_LIST_OUTSTANDING', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getOrderList({ commit }, payload) {
      try {
        console.log(payload)
        console.log(orderUrl + '/list')
        const data = await axios.post(orderUrl + '/list', payload, { headers: getHeader() })
        commit('SET_ORDER_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getOrder({ commit }, payload) {
      try {
        const data = await axios.get(orderUrl + '/' + payload.id, { headers: getHeader() })
        console.log(data.data.data)
        commit('SET_ORDER', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  }
};
